.upload {
  display: flex;
  align-items: center;
  padding: var(--spacing-m) var(--spacing-l);
  border-radius: var(--border-radius-s);
  width: auto;
  min-width: 228px;
  height: auto;
  border: 1.5px dashed #E9ECEF;
  flex: 1;
}

.upload:global(.ant-btn-variant-outlined:not(:disabled):not(.ant-btn-disabled):hover) {
  background-color: var(--color-surface2);
  border-color: #E9ECEF;
}

.circle {
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;
  background-color: #ffffff;
  box-shadow: 0px 2px 9px 0px #1D72F41F;
}
