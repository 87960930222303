.layout {
  padding: var(--spacing-none) var(--spacing-6xl);
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  position: relative;
}

.header {
  position: sticky;
  top: 0px;
  background: white;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-m);
  padding-top: var(--spacing-4xl);
  z-index: 10;
}

.body {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.pageContent {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
