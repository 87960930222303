.wrapper {
  position: sticky;
  bottom: 0px;
  height: 100px;
  padding: var(--spacing-none) var(--spacing-6xl);
  box-shadow: 0px 0px 11px 0px #00000017;
  background: white;
  margin: 0px -80px;
  margin-top: auto;
  display: flex;
  align-items: center;
  z-index: 9;
}
