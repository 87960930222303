.progressNumber {
  font-size: 18px;
  font-weight: 600;
  line-height: 21.6px;
  margin: var(--spacing-none) var(--spacing-s);
}

@media only screen and (max-width: 767px) {
  .progressNumber {
    font-size: 12px;
  }
}
