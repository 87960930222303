.tag:global(.ant-tag.ant-tag-checkable) {
  border-radius: var(--border-radius-xl);
  padding: var(--spacing-xxs) var(--spacing-s);
  border: 1.5px solid var(--color-lines);
  background-color: #ffffff;
  font-weight: 500;
  margin-inline-end: 0;
} 

.tag:global(.ant-tag.ant-tag-checkable .ant-typography) {
  color: var(--color-text-secondary);
}

.tag:global(.ant-tag.ant-tag-checkable.ant-tag-checkable-checked) {
  border-color: var(--color-primary);
  color: var(--color-primary);
  background-color: rgba(var(--color-primary-rgb), .05);
}

.tag:global(.ant-tag.ant-tag-checkable.ant-tag-checkable-checked .ant-typography) {
  color: var(--color-primary);
}

