.avatarWrapper {
  width: 100px;
  position: relative;
  cursor: pointer;
}

.avatarWrapper:hover {
  opacity: 0.8;
}

.avatarPhotoInput {
  display: none !important;
}

.avatarLabel {
  width: 100%;
  cursor: pointer;
}

.avatarAction {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  height: 100%;
}

.avatarActionIconWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: white;
  box-shadow: 0px 2px 9px 0px rgb(from var(--color-primary) r g b / 12%);
  position: absolute;
  right: 0;
  bottom: 0;
}

@media only screen and (max-width: 767px) {
  .avatarWrapper {
    margin: auto;
  }
}
