.tag:global(.ant-tag) {
  border-radius: var(--border-radius-xl);
  padding: var(--spacing-xxs) var(--spacing-s);
  border: 1.5px solid var(--color-lines);
  background-color: #ffffff;
  font-weight: 500;
  margin-inline-end: 0;
  margin-bottom: var(--spacing-xxs);
}

.tag:global(.ant-tag) {
  border-color: var(--color-primary);
  color: var(--color-primary);
  background-color: rgba(var(--color-primary-rgb), .05);
}

.tag:global(.ant-tag .ant-typography) {
  color: var(--color-primary);
}

.tag:global(.ant-tag.ant-tag-has-color .anticon.ant-tag-close-icon) {
  color: var(--color-primary);
  margin-left: 6px;
  position: relative;
}

.tag:global(.ant-tag .anticon.ant-tag-close-icon)::after {
  content: "";
  border: 1.5px solid var(--color-primary);
  border-radius: 50%;
  position: absolute;
  height: 15px;
  width: 15px;
  left: -2.5px;
}

.dropdown:global(.ant-select-dropdown .ant-select-item-option-state) {
  display: none;
}