.cancelWrapper {
  position: fixed;
  top: 180px;
  right: 76px;
}

@media only screen and (max-width: 1024px) {
  .cancelWrapper {
    top: 176px;
  }
}

@media only screen and (max-width: 767px) {
  .cancelWrapper {
    top: 164px;
  }
}