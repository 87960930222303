.searchInput {
  width: 100%;
  max-width: 340px;
  border-radius: var(--border-radius-xl);
}

.searchInput:global(.ant-input-affix-wrapper .ant-input-prefix) {
  margin-inline-end: 8px;
}

.imgWrapper {
  height: 140px;
  width: 140px;
  margin-bottom: var(--spacing-xxl);
  background-color: var(--color-surface2);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.emptyState {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: var(--spacing-4xl) var(--spacing-l);
  border: 1.5px solid var(--color-lines);
  border-radius: var(--border-radius-l);
}



.tabs :global(.ant-tabs-ink-bar.ant-tabs-ink-bar-animated) {
  display: none;
}


.tabs:global(.ant-tabs-top > .ant-tabs-nav)::before {
  display: none !important;
}


.tabs:global(.ant-tabs .ant-tabs-tab) {
  border-radius: var(--border-radius-xl);
  padding: var(--spacing-xs) var(--spacing-m);
  border: 1.5px solid var(--color-lines);
  background-color: #ffffff;
  margin-inline-end: var(--spacing-l);
  margin-bottom: var(--spacing-xxl);
  font-size: var(--font-size-m);
}

.tag:global(.ant-tag.ant-tag-checkable .ant-typography) {
  color: var(--color-text-secondary);
}

.tabs:global(.ant-tabs .ant-tabs-tab.ant-tabs-tab-active) {
  border-color: var(--color-primary);
  color: var(--color-primary);
  background-color: rgba(var(--color-primary-rgb), .05);
}

.tag:global(.ant-tag.ant-tag-checkable.ant-tag-checkable-checked .ant-typography) {
  color: var(--color-primary);
}