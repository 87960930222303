.subHeader {
  display: flex;
  align-items: center;
  height: 80px;
  min-height: 80px;
  background: var(--color-surface2);
  position: sticky;
  padding: var(--spacing-none) var(--spacing-4xl);
}

.titleWrapper {
  width: 470px;
}

.progressWrapper {
  flex-grow: 1;
}

.closeButtonWrapper {
  display: none;
}

.closeIconWrapper {
  display: none;
  cursor: pointer;
  color: var(--color-text-secondary);
  position: absolute;
  right: var(--spacing-l);
  top: var(--spacing-l);
}

.closeIconWrapper:hover {
  color: var(--color-primary);
}

@media only screen and (max-width: 1024px) {
  .subHeader {
    gap: var(--spacing-4xl);
  }

  .closeButtonWrapper {
    display: block;
  }

  .closeIconWrapper {
    display: none;
  }

  .titleWrapper {
    width: auto;
  }
}

@media only screen and (max-width: 767px) {
  .subHeader {
    flex-direction: column;
    gap: var(--spacing-none);
    padding: var(--spacing-s) var(--spacing-m);
    justify-content: space-between;
    height: 88px;
    min-height: 88px;
  }

  .closeButtonWrapper {
    display: none;
  }

  .closeIconWrapper {
    display: block;
  }

  .progressWrapper {
    flex-grow: 0;
    width: 100%;
  }
}
