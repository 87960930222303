.layout {
  overflow: auto;
}

.contentLayout {
  display: flex;
  flex-direction: row;
  overflow: auto;
}

.content {
  padding: var(--spacing-4xl);
  background: white;
  display: flex;
  flex-direction: column;
  overflow: auto;
}
