.progress {
  height: 56px;
  background: var(--color-blue-surface);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: var(--spacing-m);
  flex-shrink: 0;
  position: sticky;
}

.progressNumber {
  font-weight: 500;
  padding: 0px var(--spacing-s);
}

@media only screen and (max-width: 767px) {
  .progress {
    flex-direction: column;
    height: auto;
    padding: var(--spacing-3xl);
  }
}
