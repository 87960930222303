:root {
  --hero-height-lg: 608px;
  --hero-height-md: calc(var(--hero-height-lg) / 1.25);
  --hero-height-sm: calc(var(--hero-height-md) / 1.5);
  --hero-height-xs: calc(var(--hero-height-sm) / 1.75);

  --thumbnail-height-lg: 300px;
  --thumbnail-height-md: calc(var(--thumbnail-height-lg) / 1.25);
  --thumbnail-height-sm: calc(var(--thumbnail-height-md) / 1.5);
  --thumbnail-height-xs: calc(var(--thumbnail-height-sm) / 1.75);
}

.heroImage {
  height: var(--hero-height-lg); /* Default height for large screens */
  padding: 4px;
  @media (max-width: 991px) {
    height: var(--hero-height-md); /* Height for medium screens */
  }

  @media (max-width: 767px) {
    height: var(--hero-height-sm); /* Height for small screens */
  }

  @media (max-width: 575px) {
    height: var(--hero-height-xs); /* Height for extra small screens */
  }
}

.thumbnailImage {
  height: var(--thumbnail-height-lg); /* Default height for large screens */
  padding: 4px;
  @media (max-width: 991px) {
    height: var(--thumbnail-height-md); /* Height for medium screens */
  }

  @media (max-width: 767px) {
    height: var(--thumbnail-height-sm); /* Height for small screens */
  }

  @media (max-width: 575px) {
    height: var(--thumbnail-height-xs); /* Height for extra small screens */
  }
}

.singleImage {
  max-height: var(--hero-height-lg); 
}

.actionWrapper {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: white;
  position: absolute;
  right: 8px;
  bottom: 8px
}