.confirmModal:global(.ant-modal .ant-modal-footer) {
  text-align: start;
  margin-top: var(--spacing-xxl);
}

.confirmModal:global(.ant-modal .ant-modal-close) {
  top: 28px;
}

.circleWrapper {
  width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: var(--color-surface2);
  margin-bottom: var(--spacing-l);
}
