.pageWrapper {
  background: var(--color-surface2);
  flex-grow: 1;
}

.cardWrapper {
  max-width: 626px;
  margin: var(--spacing-6xl);
  padding: var(--spacing-4xl);
  background: white;
  border-radius: var(--border-radius-m);
  display: flex;
  flex-direction: column;
  gap: var(--spacing-4xl);
}

.iconWrapper {
  padding: var(--spacing-l);
  background: var(--color-surface2);
  border-radius: 100%;
}

.cardHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.withSeparator {
  border-bottom: 2px solid var(--color-lines);
  padding-bottom: var(--spacing-xl);
  margin-bottom: var(--spacing-xl);
}

@media only screen and (max-width: 767px) {
  .cardWrapper {
    margin: var(--spacing-m);
    padding: var(--spacing-m);
  }
}
