.wrapper {
  display: flex;
  flex-direction: column;
  overflow: auto;
}

.layout {
  display: flex;
  flex-direction: row;
  overflow: auto;
}

.content {
  background: white;
  display: flex;
  flex-direction: column;
  overflow: auto;
}

@media only screen and (max-width: 1024px) {
  .content {
    display: none;
  }

  .hasMenuId {
    display: flex;
    width: 100%;
  }
}
