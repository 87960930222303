.sidebar {
  display: flex;
  flex-direction: column;
  background: var(--color-blue-surface);
  width: 470px;
  min-width: 470px;
  padding: var(--spacing-xl) var(--spacing-none) var(--spacing-xl) var(--spacing-4xl);
  overflow: hidden;
}

.sidebar:hover {
  overflow: auto;
}

@media only screen and (max-width: 1024px) {
  .sidebar {
    display: none;
  }
}
